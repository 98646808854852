import React, {useEffect} from 'react';
import './css/app.css';
import {Container, Dimmer} from "semantic-ui-react";
import HeaderMenu from "./js/layout/HeaderMenu/HeaderMenu";
import {AppState, AuthState, LanguageState, ProductState, ProjectState, SidebarState} from "./js/types/types";
import * as Actions from './js/store/actions';
import {authLogout, CHANGE_LANGUAGE, logout, ProductSetParameterAction, toggleSidebar} from './js/store/actions';
import {Dispatch} from "redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {connect, useDispatch} from "react-redux";
import {Route, Routes, useLocation} from "react-router-dom";
import FooterMenu from "./js/layout/FooterMenu/FooterMenu";
import LoginPage from "./js/pages/LoginPage";
import ConfiguratorPage from "./js/pages/ConfiguratorPage";
import './js/i18n/i18n';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import {changeLanguage} from "./js/store/reducer/LanguageReducer";
import RegistrationPage from "./js/pages/RegistrationPage";
import ResetPasswordPage from "./js/pages/ResetPasswordPage";
import RequestPasswordResetPage from "./js/pages/RequestPasswordResetPage";


type AppStateProps = {
  authState: AuthState,
  productState: ProductState,
  projectState: ProjectState,
  languageState: LanguageState,
  sidebarState: SidebarState
};

type AppDispatchProps = {
  setParameter: typeof Actions.setParameter,
  changeLanguage: typeof Actions.changeLanguage,
  init: typeof Actions.init,
  logout: typeof Actions.logout,
  projectLogout: typeof Actions.authLogout,
  toggleSidebar: typeof Actions.toggleSidebar,
  setSidebarHideability: typeof Actions.setSidebarHideability
};

function App(props: AppStateProps & AppDispatchProps) {

  const {authState, productState, languageState} = props;
  let location = useLocation();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const logout = () => {
    props.logout();
    props.projectLogout();
  }

  useEffect(() => {

    // Init
    props.init();

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

  }, []);

  function updateDimensions() {
    let w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth;

    props.setSidebarHideability(width < 800);
  }

  function hasPermanentSidebar(): boolean {
    return location.pathname === '/' && !props.sidebarState.canBeHidden;
  }

  function toggleLocale() {

    // Set locale
    let newLanguage = languageState.language === 'de' ? 'en' : 'de';

    dispatch(changeLanguage({
      type: CHANGE_LANGUAGE,
      language: newLanguage
    }));

    i18n.changeLanguage(newLanguage);
  }

  if (productState.dataSet === null) {
    return (
        <Dimmer active={true} inverted={true}>
          <div id="custom-loader"/>
        </Dimmer>
    );
  }

  return (
      <div className={'main-content' + (hasPermanentSidebar() ? ' sidebar' : '')}>
        <Container className="content-container">

          <ToastContainer/>

          <HeaderMenu
              isAuthenticated={authState.isAuthenticated}
              username={authState.username}
              canHideSidebar={props.sidebarState.canBeHidden}
              logout={logout}
              toggleSidebarVisibility={props.toggleSidebar}/>

          <Routes>
            <Route
                path="/"
                element={<ConfiguratorPage/>}
            />
            <Route
                path="/registration"
                element={<RegistrationPage authState={authState}/>}
            />
            <Route
                path="/require-new-password"
                element={<RequestPasswordResetPage authState={authState}/>}
            />
            <Route
                path="/reset-password/:token"
                element={<ResetPasswordPage authState={authState}/>}
            />
            <Route
                path="/login"
                element={<LoginPage authState={authState}/>}
            />
            <Route
                path="/projects"
                element={<></>}
            />
            <Route
                path="/projects/:uuid"
                element={<></>}
            />
          </Routes>

          <FooterMenu locale={languageState.language} toggleLocale={() => toggleLocale()} />

        </Container>
      </div>
  );

}

function mapStateToProps(state: AppState): AppStateProps {

  return {
    authState: state.auth,
    productState: state.product,
    projectState: state.project,
    languageState: state.language,
    sidebarState: state.sidebar
  };
}

function mapDispatchToProps(dispatch: Dispatch<ProductSetParameterAction>): AppDispatchProps {
  return bindActionCreators(
      {
        setParameter: Actions.setParameter,
        changeLanguage: Actions.changeLanguage,
        init: Actions.init,
        logout: Actions.logout,
        projectLogout: Actions.authLogout,
        toggleSidebar: Actions.toggleSidebar,
        setSidebarHideability: Actions.setSidebarHideability
      },
      dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
