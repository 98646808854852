import * as React from 'react'

import * as Actions from '../../../store/actions'
import { Form, Menu } from 'semantic-ui-react'
import {ConfigurationProducts, FilteredOptions, Rule, UserSelection} from "../../../types/types";
import OptionChoiceField from "./filterForm/OptionChoiceField";
import RoofAngleSliderField from "./filterForm/RoofAngleSliderField";
import CreateModelField from "./filterForm/CreateModelField";
import CreateAssemblyField from "./filterForm/CreateAssemblyField";
import CreateSystemDependantItems from "./filterForm/CreateSystemDependantItems";
import CreateRooftopItem from "./filterForm/CreateRooftopItem";
import CreateOptionalExtras from "./filterForm/CreateOptionalExtras";
import Calculator from "./Calculator";
import AirTypeSelectionForCalculation from "./AirTypeSelectionForCalculation";
import {useTranslation} from "react-i18next";



type FilterFormProps = {
    filteredOptions: FilteredOptions,
    userSelection: UserSelection,
    products: ConfigurationProducts | null,
    locale: string,
    setParameter: typeof Actions.setParameter,
    configurations: Rule[]
};

function FilterForm(props: FilterFormProps) {

    const { t } = useTranslation();
    let {filteredOptions, userSelection, products, locale, setParameter, configurations} = props

    return (
        <div>
            <Form inverted={true}>
                <Menu.Item>
                    <Menu.Header>{t('configurator.component.filterForm.section.options')}</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item>
                            <OptionChoiceField
                                locale={locale}
                                label={t('configurator.component.filterForm.label.system')}
                                updateValue={value => setParameter('system', value)}
                                options={filteredOptions.systems}
                                value={userSelection.system}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <OptionChoiceField
                                locale={locale}
                                label={t('configurator.component.filterForm.label.airType')}
                                updateValue={value => setParameter('airType', value)}
                                options={filteredOptions.airTypes}
                                value={userSelection.airType}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <OptionChoiceField
                                locale={locale}
                                label={t('configurator.component.filterForm.label.roofType')}
                                updateValue={value => setParameter('roofType', value)}
                                options={filteredOptions.roofTypes}
                                value={userSelection.roofType}
                            />
                        </Menu.Item>
                        {filteredOptions.purposes.length > 1 && (
                            <Menu.Item>
                                <OptionChoiceField
                                    locale={locale}
                                    label={t('configurator.component.filterForm.label.purpose')}
                                    updateValue={value => setParameter('purpose', value)}
                                    options={filteredOptions.purposes}
                                    value={userSelection.purpose}
                                />
                            </Menu.Item>
                        )}
                        <Menu.Item>
                            <RoofAngleSliderField
                                label={t('configurator.component.filterForm.label.roofAngle')}
                                value={userSelection.roofAngleValue}
                                setParameter={setParameter}
                                warningShouldBeDisplayed={userSelection.roofAngleValueWarning}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <OptionChoiceField
                                locale={locale}
                                label={t('configurator.component.filterForm.label.insulation')}
                                updateValue={value => setParameter('insulation', value)}
                                options={filteredOptions.insulations}
                                value={userSelection.insulation}
                            />
                        </Menu.Item>
                        <CreateModelField
                            filteredOptions={filteredOptions}
                            setParameter={setParameter}
                            userSelection={userSelection}
                            locale={locale}
                        />
                        <CreateAssemblyField
                            filteredOptions={filteredOptions}
                            setParameter={setParameter}
                            userSelection={userSelection}
                            locale={locale}
                        />
                        <CreateSystemDependantItems
                            filteredOptions={filteredOptions}
                            setParameter={setParameter}
                            userSelection={userSelection}
                            products={products}
                        />
                    </Menu.Menu>
                </Menu.Item>
                <CreateRooftopItem
                    filteredOptions={filteredOptions}
                    setParameter={setParameter}
                    userSelection={userSelection}
                    products={products}
                    locale={locale}
                />
                <CreateOptionalExtras
                    configurations={configurations}
                    filteredOptions={filteredOptions}
                    userSelection={userSelection}
                    setParameter={setParameter}
                    products={products}
                    locale={locale}
                />
            </Form>
            <Menu.Item>
                <Menu.Header>{t('configurator.component.filterForm.section.calculator')}</Menu.Header>
                <Menu.Menu>
                    <Menu.Item>
                        <Calculator
                            setParameter={setParameter}
                            userSelection={userSelection}
                            rooftop={products !== null ? products.rooftop.product : null}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        {filteredOptions.airTypesForCalculation.length > 1 &&
                            <AirTypeSelectionForCalculation
                                locale={locale}
                                options={filteredOptions.airTypesForCalculation}
                                label={t('configurator.component.calculator.label.calculatedFor')}
                                value={userSelection.airTypeForCalculations}
                                updateValue={value => setParameter('airTypeForCalculations', value)}
                            />
                        }
                    </Menu.Item>
                </Menu.Menu>
            </Menu.Item>
        </div>
    );

}

export default FilterForm