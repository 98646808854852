import * as React from 'react';
import { Form, Input, Label, List } from 'semantic-ui-react';
import * as mathjs from 'mathjs';
import * as Actions from '../../../store/actions';
import CalculatorFactory from '../calculator/CalculatorFactory';
import {PhysicsResult, RooftopProduct, UserSelection} from "../../../types/types";
import {withTranslation} from "react-i18next";
import I18n from "../../../i18n/i18n";
import i18n from "../../../i18n/i18n";

type CalculatorProps = {
    rooftop: RooftopProduct | null,
    userSelection: UserSelection,
    setParameter: typeof Actions.setParameter,
};

type CalculatorState = {
};

class Calculator extends React.Component<CalculatorProps, CalculatorState> {

    private result: PhysicsResult = {
        v: null,
        pt: null,
        lw: null
    };

    private errors: string[] = [];

    private static format(value: number | null, precision: number) {

        if (value !== null && !Number.isNaN(value)) {
            return mathjs.format(value < 0 ? 0 : value, {notation: 'fixed', precision: precision});
        }

        return '--';
    }

    public render() {
        this.calculate();
        let flowRate = this.props.userSelection.flowRate ? this.props.userSelection.flowRate : '';

        return (
            <Form inverted={true}>
                <Form.Field className="flow-rate">
                    <Input
                        labelPosition="right"
                        placeholder={I18n.t('configurator.component.calculator.label.flowRate')}
                    >
                        <input
                            onChange={e => this.setV(e.target.value)}
                            value={flowRate}
                            maxLength={6}
                        />
                        <Label>m³/h</Label>
                    </Input>
                    {this.renderErrors()}
                </Form.Field>

                <List>
                    <List.Item>
                        {I18n.t('configurator.component.calculator.label.loudness')}
                        <List.Content floated="right">
                            {Calculator.format(this.result.lw, 0)} dB(A)
                        </List.Content>
                    </List.Item>

                    <List.Item>
                        {I18n.t('configurator.component.calculator.label.pressureLoss')}
                        <List.Content floated="right">
                            {Calculator.format(this.result.pt, 1)} Pa
                        </List.Content>
                    </List.Item>

                    <List.Item>
                        {I18n.t('configurator.component.calculator.label.speed')}
                        <List.Content floated="right">
                            {Calculator.format(this.result.v, 1)} m/s
                        </List.Content>
                    </List.Item>
                    {this.renderAirSpeedErrors()}
                </List>
            </Form>
        );
    }

    private renderAirSpeedErrors() {
        if (this.props.rooftop && this.result.v) {
            if (this.props.rooftop.airSpeedMin && this.result.v < this.props.rooftop.airSpeedMin) {
                return (
                    <Label basic={true} color="red" pointing="above">
                        {i18n.t('configurator.component.calculator.error.airSpeedMin', {
                            'min': this.props.rooftop.airSpeedMin.toString(),
                        })}
                    </Label>
                );
            }

            if (this.props.rooftop.airSpeedMax && this.result.v > this.props.rooftop.airSpeedMax) {
                return (
                    <Label basic={true} color="red" pointing="above">
                        {i18n.t('configurator.component.calculator.error.airSpeedMax', {
                            'max': this.props.rooftop.airSpeedMax.toString()
                        })}
                    </Label>
                );
            }
        }

        return null;
    }

    private renderErrors() {
        let errors: JSX.Element[] = this.errors.map((message, index) => (
            <Label key={index} basic={true} color="red" pointing="above">{message}</Label>
        ));

        return <div>{errors}</div>;
    }

    private setV(V: string) {
        let parsedValue = Number.parseInt(V);

        if (isNaN(parsedValue)) {
            this.props.setParameter('flowRate', null);
        }

        this.props.setParameter('flowRate', V as string);
    }

    private calculate() {
        let V = this.props.userSelection.flowRate;
        let calculator = CalculatorFactory.getCalculator(this.props.rooftop, this.props.userSelection);

        if (calculator) {
            this.errors = calculator.getErrors();

            if (V) {
                this.result = calculator.calculate(V);
            }
        }
    }
}

export default withTranslation()(Calculator)