import * as React from 'react';
import {
    ConfigurationProducts, FilteredOptions, SelectedExtras, Product,
    UserSelection, Rule
} from "../../../../types/types";
import { Menu, Checkbox, CheckboxProps } from 'semantic-ui-react';
import * as Actions from '../../../../store/actions';
import I18n from "../../../../i18n/i18n";
import {withTranslation} from "react-i18next";

interface Props {
    filteredOptions: FilteredOptions,
    userSelection: UserSelection,
    setParameter: typeof Actions.setParameter,
    products: ConfigurationProducts | null,
    locale: string,
    configurations: Rule[]
}

interface State {
}

class CreateOptionalExtras extends React.PureComponent<Props, State> {
    public render() {
        const { extras } = this.props.filteredOptions;
        const { products } = this.props;

        const changeExtraSelection = ( extra: Product, isSelected: boolean ) => {
            const updatedSelectedExtras: SelectedExtras = isSelected
                ? [...this.props.userSelection.extras, extra.id]
                : this.props.userSelection.extras.filter(selectedExtra => selectedExtra !== extra.id);

            this.props.setParameter('extras', updatedSelectedExtras)
        };

        const extraIsSelected = (extra: Product): boolean => this.props.userSelection.extras.includes(extra.id);

        const isExtraMandatory = (extra: Product): boolean => {
            const transition = products && products.transition ? products.transition : null;
            const rooftop = products && products.rooftop ? products.rooftop : null;

            if (transition && rooftop) {
                let filtered = this.props.configurations.filter(item => {
                    return (item.transitions.length === 0 || item.transitions.includes(transition.id)) &&
                        (item.rooftops.length === 0 || item.rooftops.includes(rooftop.id));
                });
                const config = filtered.find(cfg => cfg.extra === extra.id);
                if (config) {
                    return config.mandatory
                }
            }

            return false
        };

        const extraLabel = (extra: Product) => extra.translations[this.props.locale].title;

        const allPossibleExtrasSelected = this.props.userSelection.extras.length === extras.length;

        return (
            extras && extras.length > 0
                ? (
                    <Menu.Item>
                        <Menu.Header>{I18n.t('configurator.component.filterForm.section.extras.header')}</Menu.Header>
                        <Menu.Menu>
                            {!allPossibleExtrasSelected
                                ? <Menu.Item size="tiny" color="red">
                                    {I18n.t('configurator.component.filterForm.section.extras.warning')}
                                </Menu.Item>
                                : null
                            }
                            {extras.map((extra: Product) => {
                                return (
                                    <Menu.Item key={extra.id}>
                                        <Checkbox
                                            disabled={isExtraMandatory(extra)}
                                            label={extraLabel(extra)}
                                            checked={extraIsSelected(extra)}
                                            onChange={
                                                (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                                    if (typeof data.checked !== 'undefined') {
                                                     changeExtraSelection(extra, data.checked) }
                                                    return null
                                                }
                                            }
                                        />
                                    </Menu.Item>
                                )
                            })}
                        </Menu.Menu>
                    </Menu.Item>
                )
                : null
        )
    }
}

export default withTranslation()(CreateOptionalExtras)
