import * as React from 'react';
import { ConfigurationProducts, FilteredOptions, UserSelection } from "../../../../types/types";
import * as Actions from '../../../../store/actions';
import { Menu, Form } from 'semantic-ui-react';
import ProductChoiceField from './ProductChoiceField';
import LouverSelectionField from './LouverSelectionField';
import {withTranslation} from "react-i18next";
import I18n from "../../../../i18n/i18n";


type Props = {
    filteredOptions: FilteredOptions,
    userSelection: UserSelection,
    setParameter: typeof Actions.setParameter,
    products: ConfigurationProducts | null,
    locale: string
};

type State = {};

class CreateRooftopItem extends React.PureComponent<Props, State> {
    public render() {
        if (this.props.filteredOptions.rooftops.length === 0) {
            return null;
        }

        const rooftop = this.props.products && this.props.products.rooftop ? this.props.products.rooftop : null;
        if ( rooftop === null || rooftop.product === null || rooftop.product.louverMin === null
            || rooftop.product.louverMax === null) {
            return null;
        }

        // const isLouverRooftopRectangular = rooftop && rooftop.product.system === 2 &&
        //     rooftop.product.louverDefault && rooftop.product.louverMin < rooftop.product.louverMax;

        const isLouverRooftop = rooftop && rooftop.product.louverDefault &&
          rooftop.product.louverMin < rooftop.product.louverMax;

        return (
            <Menu.Item>
                <Menu.Header>{I18n.t('configurator.component.filterForm.section.rooftops')}</Menu.Header>
                <Menu.Menu>
                    <Menu.Item>
                        <ProductChoiceField
                            updateValue={value => this.props.setParameter('rooftop', value)}
                            locale={this.props.locale}
                            products={this.props.filteredOptions.rooftops}
                            value={this.props.userSelection.rooftop}
                        />
                    </Menu.Item>
                    {isLouverRooftop
                        ? <Menu.Item>
                            <Form.Field>
                                <LouverSelectionField
                                    louverSelectionValues={this.props.filteredOptions.louvers}
                                    currentLouverSelection={this.props.userSelection.louverSelection}
                                    minLouverCnt={rooftop.product.louverMin}
                                    maxLouverCnt={rooftop.product.louverMax}
                                    updateValue={value => this.props.setParameter('louverSelection', value)}
                                    warningShouldBeDisplayed={this.props.userSelection.louverSelIntModified}
                                />
                            </Form.Field>
                        </Menu.Item>
                        : null}
                </Menu.Menu>
            </Menu.Item>
        );
    }
}

export default withTranslation()(CreateRooftopItem);
