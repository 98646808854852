import React from "react";
import {Icon, Menu, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface MenuHeaderProps {
  isAuthenticated: boolean,
  username: string | null,
  canHideSidebar: boolean,
  logout: Function,
  toggleSidebarVisibility: Function
}

function HeaderMenu(props: MenuHeaderProps) {

  const { t } = useTranslation();
  const isSmallScreen: boolean = props.canHideSidebar

  return (
      <Menu text={true} className="header-menu">
        {props.canHideSidebar ?
            <Menu.Item as="a" header={true} onClick={() => props.toggleSidebarVisibility()}>
              <Icon name="sidebar" size="big"/>
            </Menu.Item>
            : ''}
        {props.isAuthenticated
            ? <Menu.Item as="a" header={true} onClick={() => props.logout()}>
              <Icon name="user outline"/>
              {!isSmallScreen ? <span>{props.username}&nbsp;</span> : null}
              <span className="logout-link">
                  ({t('common.component.header.logout')})
              </span>
            </Menu.Item>
            : <Menu.Item as={Link} to="/login" header={true}>
              <Icon name="sign in"/>
              {t('common.component.header.login')}
            </Menu.Item>}
        <Menu.Menu position="right">
          <Menu.Item as={Link} to="/" class="linked-logo">
            <Image src="/imgs/rokaflex-logo.svg" height={isSmallScreen ? 30 : 40}/>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
  )

}

export default HeaderMenu;