import { Physics, PhysicsResult } from '../../../types/types';
import AbstractCalculator from './AbstractCalculator';
import {Parser} from "mathjs";
import I18n from "../../../i18n/i18n";

export default class RoundSystemCalculator extends AbstractCalculator {
    protected getPhysics(): Physics | null {
        let physicsAccordingToAirType = this.physics.filter(item => {
            return item.airType === this.userSelection.airTypeForCalculations
        })

        let filtered = physicsAccordingToAirType.filter(item => {
            return this.userSelection.diameter !== null && this.userSelection.diameter <= (item.DN || 0);
        });

        if (filtered.length > 0) {
            return filtered[0];
        }

        return null;
    }

    protected createResult(parser: Parser): PhysicsResult {
        parser.set('DN', this.userSelection.diameter);
        parser.set('L', this.userSelection.louverSelection || 0);

        return {
            v: parser.evaluate('calcV(DN, V, L)'),
            pt: parser.evaluate('calcPt(DN, V, L)'),
            lw: parser.evaluate('calcLw(DN, V, L)')
        };
    }

    public getErrors(): string[] {
        let maxDiameter = this.getMaxDiameter();

        if (maxDiameter && this.userSelection.diameter && this.userSelection.diameter > maxDiameter) {
            return [I18n.t('configurator.calculator.roundSystemCalculator.error.maxDiameter', {max: maxDiameter})];
        }

        return [];
    }

    private getMaxDiameter(): number | null {
        let physicsLength = this.physics.length;
        if (physicsLength > 0) {
            return this.physics[physicsLength - 1].DN || null;
        }

        return null;
    }
}
