import * as React from 'react';
import * as Action from '../../../../store/actions';
import { Form, Statistic, Label } from 'semantic-ui-react';
import { throttle } from 'lodash';


type Props = {
    label: string,
    value: number | null,
    setParameter: typeof Action.setParameter,
    warningShouldBeDisplayed: boolean
};

export default class RoofAngleSliderField extends React.PureComponent<Props> {
    private throtteledUpdateParameters = throttle(
        (value: number) => {
            this.props.setParameter('roofAngle', value === 0 ? '1' : '2');
            this.props.setParameter('roofAngleValue', value.toString());
        },
        250,
        {
            leading: false,
            trailing: true
        }
    );

    public render() {
        let warnings: string[] = [];
        if (this.props.warningShouldBeDisplayed) {
            warnings.push('roofAngleStandardValueExceeded');
        }
        return (
            <Form.Field>
                <label>{this.props.label}</label>
                <Statistic.Group size="mini" widths="one">
                    <Statistic inverted={true} color="grey">
                        <Statistic.Value>{this.props.value} &deg;</Statistic.Value>
                    </Statistic>
                </Statistic.Group>
                <input
                    type="range"
                    min="0"
                    max="60"
                    style={{width: '100%'}}
                    defaultValue={this.props.value === null ? '0' : this.props.value.toString()}
                    onChange={this.onChange}
                />
                {warnings.map(warning => (
                    <Label key={warning} basic={true} color="orange" pointing="above">{warning}</Label>
                ))}
            </Form.Field>
        );
    }

    private onChange = (event: { persist: () => void; currentTarget: { value: string; }; }) => {
        event.persist();
        this.throtteledUpdateParameters(Number.parseInt(event.currentTarget.value))
    };
}
