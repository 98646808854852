import * as React from 'react'

import { Form, Icon, Label, Popup } from 'semantic-ui-react'
import Choice from "../../../../components/common/Choice";


type Props = {
  louverSelectionValues: (number | string)[],
  currentLouverSelection: number | null,
  minLouverCnt: number | null,
  maxLouverCnt: number | null,
  updateValue: (value: string) => void
  warningShouldBeDisplayed: boolean
};

export default class LouverField extends React.Component<Props> {
  public render () {
    let errors: string[] = []
    let warnings: string[] = []

    let louverSelection = this.props.currentLouverSelection

    if (louverSelection) {
      if (this.props.minLouverCnt && louverSelection < this.props.minLouverCnt) {
        errors.push(
          'configurator.component.filterForm.error.min' +
          { value: this.props.minLouverCnt })
      }

      if (this.props.maxLouverCnt && louverSelection > this.props.maxLouverCnt) {
        errors.push(
          'configurator.component.filterForm.error.max' +
          { value: this.props.maxLouverCnt })
      }

      if (this.props.warningShouldBeDisplayed) {
        warnings.push('louverSelectionModified')
      }
    }

    return (
      <Form.Field>

        <Popup
          content={'louverSelection'}
          trigger={
            <div>
              <label>{'louverSelection'}</label>
              <Icon circular={true} name="help circle"/>
            </div>
          }
        />

        <Choice
          choices={this.createLouverSelectionChoices()}
          value={this.props.currentLouverSelection}
          updateValue={this.props.updateValue}
          error={errors.length > 0}
        />
        {errors.map(message => (
          <Label key={message} basic={true} color="red" pointing="above">{message}</Label>
        ))}
        {warnings.map(warning => (
          <Label key={warning} basic={true} color="orange" pointing="above">{warning}</Label>
        ))}
      </Form.Field>
    )
  }

  private createLouverSelectionChoices () {
    return [
      ...this.props.louverSelectionValues.map(value => ({
        key: value,
        value: value,
        text: value.toString()
      }))
    ]
  }
}
