
//import Nullable = BABYLON.Nullable

interface LoadingScreen {
    // What happens when loading starts
    displayLoadingUI: () => void;
    // What happens when loading stops
    hideLoadingUI: () => void;
    // default loader support. Optional!
    loadingUIBackgroundColor: string;
    loadingUIText: string;
}

export default class RokaflexLoadingScreen implements LoadingScreen {
    // optional, but needed due to interface definitions

    private _loadingDiv: HTMLDivElement | null | undefined
    private _loadingTextDiv: HTMLDivElement | undefined

    constructor(
        private _renderingCanvas: HTMLCanvasElement,
        private _loadingText: string = '',
        private _loadingDivBackgroundColor: string = '')
    {}

    public displayLoadingUI(): void {
        if (this._loadingDiv) {
            // Do not add a loading screen if there is already one
            return
        }

        this._loadingDiv = document.createElement('div')
        this._loadingDiv.id = 'rokaflexLoadingDiv'

        // Loading img
        let imgBack = new Image()
        imgBack.src = '/ico/appicon.png'
        imgBack.id = 'loadingImage'
        this._loadingDiv.appendChild(imgBack)

        this._resizeLoadingUI()

        window.addEventListener('resize', this._resizeLoadingUI)

        this._loadingDiv.style.backgroundColor = this._loadingDivBackgroundColor
        document.body.appendChild(this._loadingDiv)
    }

    public hideLoadingUI(): void {

        if (!this._loadingDiv) {
            return
        }

        let onTransitionEnd = () => {
            if (!this._loadingDiv) {
                return
            }

            document.body.removeChild(this._loadingDiv)
            window.removeEventListener('resize', this._resizeLoadingUI)

            this._loadingDiv = null
        }

        this._loadingDiv.style.opacity = '0'
        this._loadingDiv.addEventListener('transitionend', onTransitionEnd)
    }

    public set loadingUIText(text: string) {
        this._loadingText = text

        if (this._loadingTextDiv) {
            this._loadingTextDiv.innerHTML = this._loadingText
        }
    }

    public get loadingUIBackgroundColor(): string {
        return this._loadingDivBackgroundColor
    }

    public set loadingUIBackgroundColor(color: string) {
        this._loadingDivBackgroundColor = color

        if (!this._loadingDiv) {
            return
        }

        this._loadingDiv.style.backgroundColor = this._loadingDivBackgroundColor
    }

    // Resize
    private _resizeLoadingUI = () => {
        let canvasRect = this._renderingCanvas.getBoundingClientRect()
        let canvasPositioning = window.getComputedStyle(this._renderingCanvas).position

        if (!this._loadingDiv) {
            return
        }

        this._loadingDiv.style.position = (canvasPositioning === 'fixed') ? 'fixed' : 'absolute'
        this._loadingDiv.style.left = canvasRect.left + 'px'
        this._loadingDiv.style.top = canvasRect.top + 'px'
        this._loadingDiv.style.width = canvasRect.width + 'px'
        this._loadingDiv.style.height = canvasRect.height + 'px'
    }
}